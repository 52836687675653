import React from "react";
import {
  HighlightedText,
  addUtmParams,
  urlCreateAccount,
  advertedPoolSize,
} from "@userbrain/website-ui";

import Layout from "../../layouts/layout";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HeaderCompare } from "../../components/HeaderCompare";
import { HtmlContent } from "../../components/HtmlContent";
import { Testimonials } from "../../components/Testimonials";
import {
  testimonialThomas,
  testimonialJoshua,
  testimonialPaul,
} from "../../helpers";
import * as helperStyles from "../helpers.module.css";
import * as styles from "./compare.module.css";

import imageDashboardSessions from "../../images/compareDashboardSessions.jpg";
import { AutoLink } from "../../components/helpers/AutoLink";
import { CompareLastParagraph } from "../../components/helpers/CompareLastParagraph";

const ContentPage = () => (
  <Layout
    title={"UserTesting vs. Userbrain"}
    metaDescription={
      "Thinking about switching from UserTesting to Userbrain? We wanted to make the decision a little easier for you - compare the two side by side and decide."
    }
  >
    <article>
      <HeaderCompare
        title={
          <>
            <span className={helperStyles.h1Serif}>UserTesting vs.</span>{" "}
            Userbrain
          </>
        }
        content={
          "Trying to choose between UserTesting and Userbrain? It's essential to understand how these two user testing platforms stack up against each other. Here are eight critical differences between Userbrain and UserTesting to aid your decision:"
        }
        imageSrc={imageDashboardSessions}
        imageAlt={
          "Userbrain dashboard showing a list of remote user testing videos"
        }
      />
      <div className={helperStyles.container}>
        <HtmlContent className={styles.htmlContent}>
          <h2>1. Ease of Onboarding</h2>
          <p>
            At Userbrain, we believe in simplicity.{" "}
            <a href={addUtmParams(urlCreateAccount, "website", "compare")}>
              Sign up and start testing
            </a>{" "}
            within minutes, bypassing the tiresome procedure of scheduling calls
            and product demos often required by UserTesting.
          </p>

          <h2>2. Flexibility at Its Best</h2>
          <p>
            Unlike UserTesting's annual contracts, Userbrain caters to the
            evolving business landscape with pay-as-you-go or monthly
            subscription options. We champion the flexibility that today's
            dynamic business climate demands.
          </p>

          <h2>3. Transparent Pricing</h2>
          <p>
            At Userbrain, transparency is one of our core values. Our{" "}
            <AutoLink to="/pricing">pricing page</AutoLink> lays out all costs
            and services in black and white, unlike UserTesting, which doesn't
            offer any listed prices. Be it a corporation or a solopreneur,
            everyone enjoys the same clear pricing.
          </p>

          <h2>4. Standard Features for All</h2>
          <p>
            Userbrain's services don't discriminate based on your subscription.
            Everyone gets to utilize all{" "}
            <AutoLink to="/features">our features</AutoLink>, including access
            to a worldwide pool of testers and the ability to specify your
            participants by device type, language, age, gender, and region.
            UserTesting, conversely, isn't as forthright about what's included
            in their packages.
          </p>

          <h2>5. Efficient User Testing</h2>
          <p>
            Userbrain's interface is designed for efficiency, focusing on what
            truly matters for user testing. Our commenting and automated testing
            features streamline your process, unlike UserTesting, whose abundant
            features can leave you feeling overwhelmed.
          </p>

          <h2>6. AI Insights</h2>
          <p>
            To stand out, Userbrain employs{" "}
            <AutoLink href="https://www.userbrain.com/blog/automated-insights-streamline-user-test-analysis-with-ai-assistance">
              AI Insights
            </AutoLink>
            , which expedites your user test analysis process. Get faster and
            deeper understanding of user behaviors to build a more user-friendly
            product.
          </p>

          <h2>7. Automated versus Manual Testing</h2>
          <p>
            With Userbrain, you can automate your user tests, turning them into
            a routine habit. Unlike UserTesting's old school, manual testing
            method, we're here to save you time and brainpower.
          </p>

          <h2>8. Keeping Promises</h2>
          <p>
            Userbrain offers only what it can deliver. While UserTesting allows
            you to target unlikely user profiles, we provide real-time
            information about tester availability from our global pool of{" "}
            <AutoLink to="/features/tester-pool/">
              over {advertedPoolSize} qualified testers
            </AutoLink>
            . No empty promises, just effective solutions.
          </p>

          <CompareLastParagraph />
        </HtmlContent>
      </div>
      <div className={helperStyles.container}>
        <Testimonials
          testimonials={[testimonialJoshua, testimonialThomas, testimonialPaul]}
        />
        <GetStartedBox
          className={helperStyles.spacingLg}
          title={
            <>
              Ready for your first{" "}
              <HighlightedText cursor>user test?</HighlightedText>
            </>
          }
          textBottom={
            "Get set up in minutes. Your first test is ready in a few hours."
          }
        />
      </div>
    </article>
  </Layout>
);

export default ContentPage;
